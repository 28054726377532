#contact-form {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 80%;
    margin: 0 10%;
}

.form-label {
    font-size: 16px;
    margin-right: 5%;
    font-family: "Antonio", sans-serif;
}

.contact-form-col:has(.form-label) {
    grid-column: 2 / span 1;
}

.form-input {
    width: 90%;
    height: 30px;
    border: solid black 1px;
    font-family: "Antonio", sans-serif;
}

#message-input {
    height: 100px;
    resize: none;
    font-family: "Antonio", sans-serif;
}

.contact-form-col:has(.form-input) {
    grid-column: span 10;
}

#contact-submit-btn {
    width: 100%;
    height: 40px;
    color: white!important;
    border: solid white 1px;
    background-color: #482b7b!important;
    text-align: center;
    font-family: "Antonio", sans-serif!important;
    cursor: pointer;
    transition: 0.5s;
}

#contact-submit-btn:hover {
    background-color: white!important;
    color: #482b7b!important;
    border: solid #482b7b 1px;
    transition: 0.5s;
}

.contact-form-col:has(#contact-submit-btn) {
    grid-column: 3 / span 2;
}

@media (max-width: 1249px) and (min-width: 992px) {
    #contact-form {
        width: 100%;
        margin: 0 0%;
    }

    .contact-form-col:has(.form-label) {
        grid-column: 1 / span 1;
    }

    .form-input {
        width: 100%!important;
    }
}

@media (max-width: 991px) and (min-width: 768px){
    #contact-form {
        width: 100%;
        margin: 0 0%;
    }

    .contact-form-col:has(.form-label) {
        grid-column: 1 / span 1;
    }

    .form-input {
        width: 100%!important;
    }

    .contact-form-col:has(#contact-submit-btn) {
        grid-column: 2 / span 3;
    }
}

@media (max-width: 767px) {
    #contact-form {
        width: 100%;
        margin: 0 0%;
    }

    .contact-form-col:has(.form-label) {
        grid-column: 1 / span 1;
    }

    .form-input {
        width: 100%!important;
    }

    .contact-form-col:has(#contact-submit-btn) {
        grid-column: 2 / span 5;
    }
}