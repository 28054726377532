#our-services-container {
    width: 90%;
    margin: 2% 5%;
}

#our-services {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.service-col {
    grid-column: span 2;
    /* animation: flip-back 1s;
    animation-fill-mode: forwards; */
}




/* .service-img:hover {
    animation: flip-image 1s;
    animation-fill-mode: forwards;
} */

.service-card {
    position: relative;
}

.service-btn {
    color: white !important;
    background-color: #482b7b !important;
    width: 40% !important;
    position: absolute;
    top: 80%;
    left: 40%;
    transform: translate(-25%, -20%);
}


/* @keyframes flip-image {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(180deg);
    }
}

@keyframes flip-back {
    0% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(0deg);
    }
} */

@media (min-width: 2054px) {
    #our-services-container {
        width: 90%;
        margin: 2% 5%;
    }
    
    .service-col:nth-child(1) {
        grid-column: 2 / span 2;
    }

    .service-col:nth-child(6) {
        grid-column: 2 / span 2;
    }
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #our-services-container {
        width: 80%;
        margin: 2% 10%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 3;
    }

    .service-col:nth-child(9) {
        grid-column: 4 / span 3;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #our-services-container {
        width: 80%;
        margin: 2% 10%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 4;
    }

    .service-col:nth-child(10) {
        grid-column: 5 / span 4;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #our-services-container {
        width: 80%;
        margin: 2% 10%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 4;
    }

    .service-col:nth-child(10) {
        grid-column: 5 / span 4;
    }

    .service-btn {
        width: 50% !important;
        transform: translate(-30%, -20%);
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #our-services-container {
        width: 80%;
        margin: 2% 10%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 6;
    }

    .service-btn {
        width: 50% !important;
        transform: translate(-30%, -20%);
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #our-services-container {
        width: 80%;
        margin: 2% 10%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 6;
    }

    .service-btn {
        width: 50% !important;
        transform: translate(-30%, -20%);
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #our-services-container {
        width: 80%;
        margin: 2% 10%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 12;
    }

    .service-btn {
        width: 50% !important;
        transform: translate(-30%, -20%);
    }
}

@media (max-width: 361px) {
    #our-services-container {
        width: 90%;
        margin: 2% 5%;
    }

    #our-services {
        grid-template-columns: repeat(12, 1fr);
    }

    .service-col {
        grid-column: span 12;
    }

    .service-btn {
        width: 50% !important;
        transform: translate(-30%, -20%);
    }
}