#quote-form {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 80%;
    margin: 0 10%;
}

.form-label {
    font-size: 16px;
    margin-right: 5%;
    font-family: "Antonio", sans-serif;
}

.quote-form-col:has(.form-label) {
    grid-column: 2 / span 1;
}

.quote-form-col:has(.form-label.service) {
    grid-column: span 12;
}

.form-input {
    width: 90%;
    height: 30px;
    border: solid black 1px;
    font-family: "Antonio", sans-serif;
}

.form-input.checkbox-list {
    border: solid transparent 1px;
    height: 100%;
}

.form-checkbox {
    margin-left: 5%;
}

.form-checkbox-label {
    font-family: "Antonio", sans-serif;
}

#message-input {
    height: 100px;
    resize: none;
    font-family: "Antonio", sans-serif;
}

.quote-form-col:has(.form-input) {
    grid-column: span 10;
}

#quote-submit-btn {
    width: 100%;
    height: 40px;
    color: white!important;
    border: solid white 1px;
    background-color: #482b7b!important;
    text-align: center;
    font-family: "Antonio", sans-serif!important;
    cursor: pointer;
    transition: 0.5s;
}

#quote-submit-btn:hover {
    background-color: white!important;
    color: #482b7b!important;
    border: solid #482b7b 1px;
    transition: 0.5s;
}

.quote-form-col:has(#quote-submit-btn) {
    grid-column: 3 / span 2;
}

@media (max-width: 1699px) and (min-width: 1200px) {
    .quote-form-col:has(#quote-submit-btn) {
        grid-column: 3 / span 2;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .quote-form-col:has(#quote-submit-btn) {
        grid-column: 2 / span 3;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .quote-form-col:has(#quote-submit-btn) {
        grid-column: 2 / span 3;
    }

    #quote-form {
        width: 100%;
        margin: 0 0%;
    }
}

@media (max-width: 767px) {
    .quote-form-col:has(#quote-submit-btn) {
        grid-column: span 12;
    }

    #quote-form {
        width: 100%;
        margin: 0 0%;
        column-gap: 5px;
    }
}