#main-menu,
#mobile-main-menu {
    background-image: url("https://wz-excavating-bucket.s3.amazonaws.com/banners/navbar-backdrop.jpeg");
    background-position: 20% 30%;
    margin: 0 !important;
    background-color: #fefcfd;
}

#main-menu {
    padding: 0 30%;
}

.header-nav-item {
    font-size: 20px;
    color: white !important;
    padding-top: -3% !important;
    padding-bottom: 1% !important;
    font-family: 'Antonio', sans-serif !important;
}

.dropdown.icon {
    text-decoration: none !important;
}

.ui.secondary.menu .active.item {
    background: none !important;
    font-weight: 500 !important;
}

.ui.secondary.menu .active.item:hover {
    background: none !important;
}

.ui.secondary.pointing.menu .item {
    align-self: center!important;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #main-menu {
        padding: 0 30%;
    }
    .header-nav-item {
        font-size: 20px;
        color: white !important;
        padding-top: -3% !important;
        padding-bottom: 1% !important;
        font-family: 'Antonio', sans-serif !important;
    }
}

@media (max-width: 991px) {
    .header-nav-item {
        text-decoration: none !important;

    }

    .header-nav-item:hover {
        text-decoration: none !important;
        background-color: transparent !important;
    }

    #header-nav-stack {
        font-size: 28px;
        color: white !important;
    }

    #mobile-sidebar {
        background-color: #482b7b !important;
    }

    .ui.vertical.menu .item:before {
        width: 0%!important;
    }

    .ui.secondary.pointing.menu .item {
        align-self: center!important;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #main-menu {
        padding: 1% 5%;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #main-menu {
        padding: 2% 2%;
    }
}