#footer-container {
    background-color: #000000;
    padding: 20px 0;
}

#footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 60px;
    width: 50%;
    margin: 0 25%;
}

#footer-hours-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.footer-header,
.footer-text,
.footer-icon {
    color: #ffffff !important;
}

.footer-header,
.footer-text,
#footer-copyright {
    font-family: 'Antonio', sans-serif !important;
}

.footer-header {
    font-size: 24px !important;
    text-align: center;
}

.footer-text {
    font-size: 16px !important;
    line-height: 30px !important;
}

#footer-about {
    text-align: left;
}

#footer-contact-info {
    text-align: left;
    width: 90%;
    margin: 0 5%;
}

#footer-social-media {
    text-align: center;
}

#footer-logo {
    text-align: left !important;
}

#footer-copyright {
    text-align: center;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #footer {
        width: 60%;
        margin: 0 20%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #footer {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #footer {
        width: 90%;
        margin: 0 5%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        margin: 0 5%;
    }

    .footer-col {
        text-align: center;
    }

    .footer-col:nth-child(4) {
        grid-row: 3;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        margin: 0 5%;
    }

    .footer-col {
        text-align: center;
    }

    .footer-col:nth-child(4) {
        grid-row: 3;
    }
}

@media (max-width: 767px) {
    #footer {
        display: grid;
        grid-template-columns: 1fr;
        width: 90%;
        margin: 0 5%;
    }

    .footer-col {
        text-align: center;
    }

    .footer-col:nth-child(4) {
        grid-row: 5;
    }
}