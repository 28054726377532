#about-container {
    width: 60%;
    margin: 0 20%;
    padding: 1% 0;
}

#about {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
}

.about-col:first-child {
    grid-column: span 2;
    overflow-y: auto;
    max-height: 800px;
}

.about-col:first-child::-webkit-scrollbar {
    display: none;
}

#about-contact-info {
    background-color: #482b7b;
    padding: 5%;
    color: white;
}

#about-header {
    font-family: "Antonio", sans-serif;
}

.about-subheader.first-col {
    font-family: "Antonio", sans-serif!important;
    color: #482b7b!important;
}

.about-subheader.second-col {
    color: white!important;
    font-family: "Antonio", sans-serif!important;
}

#about-hours-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about-text {
    font-size: 16px!important;
    line-height: 30px!important;
}

.about-list {
    width: 75%;
    margin: 2% 20% 0 5%!important;
}

.about-list-item {
    font-family: "Antonio", sans-serif;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 2%;
}

.about-bold-text {
    color: #482b7b;
}

.ui.bound.bottom.sticky {
    bottom: -47.5%!important;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #about-container {
        width: 70%;
        margin: 0 15%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #about-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1249px) and (min-width: 992px) {
    #about-container {
        width: 100%;
        margin: 0 0%;
        padding: 1%;
    }
}

@media (max-width: 991px) {
    #about {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }

    .about-col:first-child {
        grid-column: span 1;
    }

    .about-col:last-child {
        grid-column: span 1;
    }

    #about-contact-info {
        padding: 5%;
    }

    .about-list {
        width: 100%;
        margin: 2% 0;
    }

    .about-list-item {
        margin: 2% 0;
    }
}

@media (max-width: 767px) {
    #about-container {
        width: 80%;
        margin: 0 10%;
        padding: 5% 0;
    }
}

@media (max-width: 361px) {
    #about-container {
        width: 95%;
        margin: 0 2.5%;
    }
}