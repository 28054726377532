#testimonials-container {
    background-color: #482b7b;
    background-image: url("https://wz-excavating-bucket.s3.amazonaws.com/banners/textless/patios-textless.png");
    background-size: cover;
    background-position: 0% 60%;
    padding: 2%;
}

#testimonials-header-container {
    width: 60%;
    margin: 0 20%;
}

#testimonials-header {
    text-align: center;
    margin-bottom: 2%;
    font-size: 32px;
}

#testimonials {
    width: 50%;
    margin: 0 25%;
}

.testimonial-col {
    padding: 2%;
}

.slick-slider {
    margin-left: -3%;
}

.slick-prev:before, .slick-next:before {
   font-size: 40px!important;
}

.slick-prev:before {
   margin-left: -30px;
}

.testimonial-icon {
    color: #fff!important;
    margin-bottom: 2%!important;
}

.testimonial-text {
    font-size: 16px;
        line-height: 35px;
}

#testimonials-header, .testimonial-text {
    color: #fff!important;
    font-family: 'Antonio', sans-serif!important;
    font-weight: 300;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #testimonials {
        width: 60%;
        margin: 0 20%;
    }

    .testimonial-text {
        font-size: 16px;
        line-height: 35px;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #testimonials {
        width: 70%;
        margin: 0 15%;
    }

    .slick-slider {
        margin-left: -2%;
    }

}

@media (max-width: 1199px) and (min-width: 992px) {
    #testimonials {
        width: 90%;
        margin: 0 5%;
    }

    .testimonial-text {
        font-size: 16px;
        line-height: 30px;
    }

    .slick-slider {
        margin-left: 1%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #testimonials {
        width: 60%;
        margin: 5% 20%;
    }

    .testimonial-text {
        font-size: 16px;
        line-height: 30px;
    }

    .slick-slider {
        margin-left: 2%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #testimonials {
        width: 60%;
        margin: 5% 20%;
    }

    .testimonial-text {
        font-size: 16px;
        line-height: 30px;
    }

    .slick-slider {
        margin-left: 2%;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #testimonials-header-container {
        width: 80%;
        margin: 2% 10%;
    }

    #testimonials {
        width: 70%;
        margin: 5% 15%;
    }

    .testimonial-text {
        font-size: 16px;
        line-height: 30px;
    }
    .slick-slider {
        margin-left: 3.5%;
    }
}

@media (max-width: 361px) {
    #testimonials-header-container {
        width: 90%;
        margin: 2% 5%;
    }

    #testimonials {
        width: 70%;
        margin: 5% 15%;
    }

    .testimonial-text {
        font-size: 16px;
        line-height: 30px;
    }
    .slick-slider {
        margin-left: 4%;
    }
}