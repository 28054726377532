#quote-container {
    width: 60%;
    margin: 0 20%;
    padding: 1% 0;
}

#quote {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

#quote-header1 {
    color: white!important;
    margin: 2% 0;
}

#quote-info {
    width: 70%;
    margin: 0 15%;
    color: white;
    font-size: 20px;
    font-family: "Antonio", sans-serif;
}

#mail-link, #phone-link {
    color: white;
    text-decoration: underline;
}

.quote-col:not(:last-child) {
    padding: 9% 0;
}

.quote-col:first-child {
    background-color: #482b7b;
    grid-column: span 1;
}

.quote-col:nth-child(2) {
    grid-column: span 2;
}

.quote-header {
    text-align: center;
    font-family: "Antonio", sans-serif!important;
}

#quote-list-header {
    color: white!important;
    margin: 2% 0;
    text-align: left;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #quote-container {
        width: 70%;
        margin: 0 15%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #quote-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #quote-container {
        width: 90%;
        margin: 0 5%;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    #quote-container {
        width: 90%;
        margin: 0 5%;
        padding: 5% 0;
    }

    #quote {
        row-gap: 40px;
    }

    .quote-col:nth-child(1), .quote-col:nth-child(2) {
        grid-column: span 3;
    }
}

@media (max-width: 767px) {
    #quote-container {
        width: 90%;
        margin: 0 5%;
        padding: 5% 0;
    }

    #quote {
        row-gap: 40px;
    }

    .quote-col:nth-child(1), .quote-col:nth-child(2) {
        grid-column: span 3;
    }
}