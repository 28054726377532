#gravel-container {
    background-color: #482b7b;
}

#gravel-banner {
    height: 700px;
    object-fit: cover;
}

#gravel {
    width: 60%;
    margin: 0% 20%;
    padding: 2%;
    background-color: #fff;
}

.gravel-header {
    font-family: 'Antonio', sans-serif;
    color: #482b7b;
    width: 50%!important;
    margin: 2% 25%!important;
    text-align: center;
}

.gravel-subheader {
    font-family: 'Antonio', sans-serif;
    color: #482b7b!important;
    width: 80%!important;
    margin: 2% 10% 1%!important;
    font-size: 24px!important;
}

.gravel-text {
    font-family: 'Antonio', sans-serif;
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    margin: 2% 10%;
}

.gravel-img {
    width: 80%!important;
    margin: 0 10%!important;
    height: 700px!important;
    object-fit: cover!important;
}

#gravel-list {
    width: 40%;
    margin: 0 30%;
}

.gravel-list-item {
    font-family: 'Antonio', sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: #482b7b;
    margin-bottom: 2%;
}

.gravel-bold-text {
    color: #482b7b;
}

#gravel-btn-container {
    text-align: center;
    margin: 2% 0;
}

.gravel-btn {
    color: white!important;
    background-color: #482b7b!important;
    width: 15%!important;
    border: 1px solid #fff!important;
    margin: 0 1%!important;
    transition: 0.5s!important;
}

.gravel-btn:hover {
    background-color: #fff!important;
    color: #482b7b!important;
    border: 1px solid #482b7b!important;
    transition: 0.5s!important;
}

#gravel-last-line {
    width: 90%;
    margin: 0 5%;
    margin-top: 2%;
    text-align: center;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #gravel {
        width: 70%;
        margin: 0 15%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #gravel {
        width: 80%;
        margin: 0 10%;
    }

    .gravel-text {
        width: 90%;
        margin: 2% 5%;
    }

    .gravel-img {
        width: 90%!important;
        margin: 0 5%!important;
        height: 600px!important;
        object-fit: cover!important;
    }

    .gravel-subheader {
        width: 90%!important;
        margin: 2% 5% 1%!important;
    }

    #gravel-list {
        width: 50%;
        margin: 0 25%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #gravel {
        width: 80%;
        margin: 0 10%;
    }

    .gravel-text {
        width: 90%;
        margin: 2% 5%;
    }

    .gravel-img {
        width: 90%!important;
        margin: 0 5%!important;
        height: 600px!important;
        object-fit: cover!important;
    }

    .gravel-subheader {
        width: 90%!important;
        margin: 2% 5% 1%!important;
    }

    #gravel-list {
        width: 50%;
        margin: 0 25%;
    }

    .gravel-btn {
        width: 20%!important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    #gravel {
        width: 100%;
        margin: 0 0%;
    }

    #gravel-banner {
        height: auto;
    }

    .gravel-text {
        width: 90%;
        margin: 2% 5%;
    }

    .gravel-img {
        width: 90%!important;
        margin: 0 5%!important;
        height: 600px!important;
        object-fit: cover!important;
    }

    .gravel-subheader {
        width: 90%!important;
        margin: 2% 5% 1%!important;
    }

    .gravel-header {
        margin-top: 5%!important;
    }

    #gravel-list {
        width: 60%;
        margin: 0 20% 5%;
    }

    .gravel-btn {
        width: 30%!important;
    }
}

@media (max-width: 767px) {
    #gravel {
        width: 100%;
        margin: 0 0%;
    }

    #gravel-banner {
        height: auto;
    }

    .gravel-text {
        width: 90%;
        margin: 2% 5% 10%;
    }

    .gravel-img {
        width: 90%!important;
        margin: 0 5%!important;
        height: 300px!important;
        object-fit: cover!important;
    }

    .gravel-subheader {
        width: 90%!important;
        margin: 10% 5% 3%!important;
    }

    .gravel-header {
        width: 70%!important;
        margin: 10% 15% 5%!important;
    }

    #gravel-list {
        width: 80%;
        margin: 0 10% 10%;
    }

    .gravel-btn {
        width: 40%!important;
    }
}