#about-us-container {
    background-color: #482b7b;
    padding: 2% 20%;
}

#about-us {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
    row-gap: 20px;
}

#about-us-header, #about-us-subheader, 
#choose-us-header, .about-us-text,
.choose-us-list-item, .choose-us-list-header,
.choose-us-list-desc {
    color: #ffffff!important;
    font-family: 'Antonio', sans-serif!important;
}

#about-us-btn-container {
    text-align: center;
}

#about-us-btn, #get-quote-btn{
    width: 30%;
    margin: 0 1%;
    font-family: 'Antonio', sans-serif;
    font-weight: 400;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    transition: 0.5s;
}

#about-us-btn:hover, #get-quote-btn:hover {
    background-color: #fff;
    color: #482b7b;
    transition: 0.5s;
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #about-us-container {
        padding: 2% 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #about-us-container {
        padding: 2% 5%;
    }

    #about-us {
        column-gap: 50px;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #about-us-container {
        padding: 2% 5%;
    }

    #about-us {
        column-gap: 50px;
    }

    #about-us-btn, #get-quote-btn{
        width: 40%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #about-us-container {
        padding: 2% 10%;
    }

    #about-us {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }

    #about-us-btn, #get-quote-btn {
        width: 30%;
    }

    .about-us-col:nth-child(2) {
        grid-row: 1;
    }
}

@media (max-width: 767px)  and (min-width: 362px){
    #about-us-container {
        background-color: #482b7b;
        padding: 5% 10%;
    }

    #about-us {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }

    #about-us-btn, #get-quote-btn {
        width: 45%;
    }

    .about-us-col:nth-child(2) {
        grid-row: 1;
    }
}

@media (max-width: 361px) {
    #about-us-container {
        background-color: #482b7b;
        padding: 5% 10%;
    }

    #about-us {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }

    #about-us-btn, #get-quote-btn {
        width: 47.5%;
    }

    .about-us-col:nth-child(2) {
        grid-row: 1;
    }
};