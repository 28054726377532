#announcement-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    background-color: #482b7b;
    padding: 0.5%;
}

.announce-col:nth-child(1) {
    grid-column: 6 / span 4;
}

.announce-col:nth-child(2) {
    grid-column: span 2;
}

#announce-phone-number {
    color: white;
    font-size: 24px;
    font-family: 'Antonio', sans-serif;
}
#announce-address {
    color: white;
    font-size: 24px;
    font-family: 'Antonio', sans-serif;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #announcement-container {
        padding: 1%;
    }
    .announce-col:nth-child(1) {
        grid-column: 6 / span 4;
    }
    
    .announce-col:nth-child(2) {
        grid-column: span 3;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #announcement-container {
        padding: 1%;
    }
    .announce-col:nth-child(1) {
        grid-column: 5 / span 4;
    }
    
    .announce-col:nth-child(2) {
        grid-column: span 3;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #announcement-container {
        padding: 1%;
    }
    .announce-col:nth-child(1) {
        grid-column: 4 / span 5;
    }
    
    .announce-col:nth-child(2) {
        grid-column: span 4;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #announcement-container {
        padding: 1.5% 0 1%;
    }
    .announce-col:nth-child(1) {
        grid-column: 3 / span 6;
    }
    
    .announce-col:nth-child(2) {
        grid-column: span 3;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #announcement-container {
        padding: 1.5% 0 1%;
    }

    .announce-col:nth-child(1) {
        grid-column: 3 / span 6;
    }
    
    .announce-col:nth-child(2) {
        grid-column: span 3;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #announcement-container {
        padding: 3%;
        row-gap: 10px;
    }

    .announce-col {
        text-align: center;
    }

    .announce-col:nth-child(1) {
        grid-column: 1 / span 12;
    }
    
    .announce-col:nth-child(2) {
        grid-column: 1 / span 12;
    }

    #announce-phone-number, #announce-address {
        font-size: 20px;
    }
}

@media (max-width: 361px) {
    #announcement-container {
        padding: 3%;
        row-gap: 10px;
    }

    .announce-col {
        text-align: center;
    }

    .announce-col:nth-child(1) {
        grid-column: 1 / span 12;
    }
    
    .announce-col:nth-child(2) {
        grid-column: 1 / span 12;
    }

    #announce-phone-number, #announce-address {
        font-size: 20px;
    }
}