.home-slideshow {
    overflow: hidden;
    max-width: 100%;
}

.home-slideshow-slider {
    white-space: nowrap;
    transition: ease 1s;
    margin-bottom: -0.35%;
}

.home-slide {
    display: inline-block;
    height: 100%;
    width: 100%;
}

.home-slideshow-dots {
    text-align: center;
    background-image: url("https://wz-excavating-bucket.s3.amazonaws.com/banners/navbar-backdrop.jpeg");
    background-position: 20% 30%;
}

.home-slideshow-dot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 10px;
    background-color: #482b7b;
}

.home-slideshow-dot.active {
    border: 2px solid #bec1c0;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    .home-slideshow-slider {
        margin-bottom: -0.35%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    .home-slideshow-slider {
        margin-bottom: -0.4%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .home-slideshow-slider {
        margin-bottom: -0.5%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    .home-slideshow-slider {
        margin-bottom: -0.6%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    .home-slideshow-slider {
        margin-bottom: -0.65%;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    .home-slideshow-slider {
        margin-bottom: -1.3%;
    }
    .home-slideshow-dot {
        height: 10px;
        width: 10px;
    }
}

@media (max-width: 361px) {
    .home-slideshow-slider {
        margin-bottom: -1.6%;
    }
    .home-slideshow-dot {
        height: 10px;
        width: 10px;
    }
}